import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com"
import './contact.css'

export default function Contact() {
  // const { register, handleSubmit } = useForm();
  // const backendUrl = 'http://localhost:5555/contact'
  // function handleContact(data) {
  //   console.log(data)
  //   fetch(backendUrl, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify(data)
  //   })
  //     .then(res => {
  //       console.log(res)
  //     }
  //     )
  function sendEmail(data)
  {
    data.preventDefault();

    emailjs.sendForm('service_7hw04xc', 'template_6efznci', data.target, '4zF5FKzEra854muhd')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      data.target.reset();
  }
  
  return (
    <div className='contactContainer' id="contact">
      <Row className='justify-content-center'>
        <Col md={10}>
          <div>
            <h3 className='text-center text-white mb-5'>Contact Us</h3>
            <a className='link-color' href='mailto:service@navramlogistics.com'>
              <h5 className='text-center'>
                service@navramlogistics.com
              </h5>
            </a>
            <a className='link-color' href='tel:312-858-3956'>
              <h5 className='text-center mb-5'>
                (312)-858-3956
              </h5>
            </a>
            <div className='formContainer'>
              <form onSubmit={sendEmail}>
                <Row className='form__row'>
                  <Col xs={12} md={6} >
                    <input type="text" placeholder='First Name' name="firstName" required />
                  </Col>
                  <Col xs={12} md={6} >
                    <input type="text" placeholder='Last Name' name="lastName" required />
                  </Col>
                  <Col xs={12} md={6} >
                    <input type="text" placeholder='Company Name' name="company" required />
                  </Col>
                  <Col xs={12} md={6} >
                    <input type="text" placeholder='Address' />
                  </Col>
                  <Col xs={12} md={6} >
                    <input  type="email" placeholder='Email Address' name="email" required />
                  </Col>
                  <Col xs={12} md={6} >
                    <input type="text" placeholder='Phone Number' name="number" required />
                  </Col>
                  <Col xs={12} >
                    <textarea type="text" placeholder='Message' name="message" required />
                  </Col>
                  <Col><input className='submit_btn' xs={12} type="submit" value="Submit" /></Col>
                </Row>
              </form>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}
