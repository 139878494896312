import React from 'react'
import MyNavbar from '../../components/MyNavbar/MyNavbar'
import { Col, Row } from 'react-bootstrap'
import './blog.css'
import { useNavigate } from 'react-router-dom';
import Img1 from '../../assets/img/blueTrucks.jpg'
import Img2 from '../../assets/img/blackwhiteTruck.jpg'
import Img3 from '../../assets/img/redTruck.jpg'
import Img4 from '../../assets/img/yellowTruck.jpg'
import Img5 from '../../assets/img/truckMountains.jpg'
export default function Blog() {
  const navigate = useNavigate()
  const blogs = [
    {
      title: 'The Importance of Freight Brokers',
      description: 'Both shippers and carriers face many challenges in today’s world. Shippers would say one of their biggest challenges is finding capacity. On the other hand, carriers would say that providing capacity is one of their biggest challenges. As freight brokers, our goal is to help alleviate those challenges and connect shippers to reputable carriers. At the end of the day, everyone’s goal is the same. To get....',
      img: Img1
    },
    // {
    //   title: 'Expansion in Progress',
    //   description: '',
    //   img: Img2
    // },
    // {
    //   title: 'Expansion in Progress',
    //   description: '',
    //   img: Img3
    // },
    // {
    //   title: 'Expansion in Progress',
    //   description: '',
    //   img: Img4
    // },
  ]
  return (
    <div>
      <MyNavbar />
      <div className="blogContainer">
       
        { 
          blogs.map((blog, i) => {
            return (
              <div onClick={() => navigate('/blog/' + Number(i))} key={i} className='blog_postContainer'>
                <Row className='blog_post' >
                  <Col xs={12} md={5}>
                    <img style={{ width: '100%' }} src={blog.img} alt="" />
                  </Col>
                  <Col xs={12} md={7} className='right'>
                    <div>
                      <h3 className='mb-3'>{blog.title}</h3>
                      <p>{blog.description}</p>
                    </div>
                  </Col>
                </Row>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}
