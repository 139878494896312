import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import TravelImg from '../../assets/img/travel.jpg'
import MyNavbar from '../../components/MyNavbar/MyNavbar'
import './blogDetails.css'
import Img1 from '../../assets/img/blueTrucks.jpg'
import Img2 from '../../assets/img/blackwhiteTruck.jpg'
import Img3 from '../../assets/img/redTruck.jpg'
import Img4 from '../../assets/img/yellowTruck.jpg'
import Img5 from '../../assets/img/truckMountains.jpg'
export default function BlogDetails() {
  const { id } = useParams()
  const blogs = [
    {
      title: 'The Importance of Freight Brokers',
      description: ["Both shippers and carriers face many challenges in today’s world. Shippers would say one of their biggest challenges is",<b> finding </b>, "capacity. On the other hand, carriers would say that", <b> providing </b>, "capacity is one of their biggest challenges. As freight brokers, our goal is to help alleviate those challenges and connect shippers to reputable carriers. At the end of the day, everyone’s goal is the same. To get one load from one destination to another.", 
      <br/>,<br/>, 
      "At", <b> Navram Logistics</b>, ", we are constantly adapting as new technologies enter the transportation industry. We strive to be flexible to fit your exact needs and give you personalized service. As your business grows, the importance of having a freight broker  also increases. We have the experience of a larger brokerage but provide personalized support you’d expect from a small business. We want to support you by helping you save time and be more flexible by using our shipping expertise in LTL(less-than-truckload) and full truck load services."
      ],
      img: Img1
    },
    // {
    //   title: 'Expansion in Progress',
    //   description: '',
    //   img: Img2
    // },
    // {
    //   title: 'Expansion in Progress',
    //   description: '',
    //   img: Img3
    // },
    // {
    //   title: 'Expansion in Progress',
    //   description: '',
    //   img: Img4
    // },
  ]

  return (
    <div>
      <MyNavbar />
      <div className='detailsContainer'>
        <Row className='' >
          <Col xs={12} className='mb-5'>
            <img style={{ width: '100%' }} src={blogs[id].img} alt="" />
          </Col>
          <Col xs={12} className=''>
            <div>
              <h3 className='mb-3'>{blogs[id].title}</h3>
              <p>{blogs[id].description}</p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
